import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';

import { MAX_CHARACTERS } from '@constants';
import { SessionStorage } from '@services/api';
import { AccountStorage, FormConfirmSaveService } from '@services/core';
import { RedirectService } from '@services/shared';
import {
  Currency,
  CurrencyEnum,
  OrganizationFilterInput,
  OrganizationForm,
  OrganizationSite,
  QueryResult,
  SortOrderInput,
  UpdateOrganizationSiteInput,
} from '@typings';

import { OrganizationStorage } from './organization.storage';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  form: FormGroup<OrganizationForm>;
  organization: OrganizationSite;
  reloadOrganization: Subject<string> = new Subject();

  isSubmitDisabled$ = new BehaviorSubject(false);

  constructor(
    private organizationStorage: OrganizationStorage,
    private fb: FormBuilder,
    private formConfirmSaveService: FormConfirmSaveService,
    public accountStorage: AccountStorage,
    private redirectService: RedirectService,
    private sessionStorage: SessionStorage,
  ) {}

  initForm(organization: OrganizationSite): void {
    this.organization = organization;

    this.form = this.fb.group<OrganizationForm>({
      name: this.fb.nonNullable.control(organization.name || '', [Validators.required, Validators.maxLength(MAX_CHARACTERS.ORGANIZATION)]),
      currency: this.fb.nonNullable.control(organization.currency || this.sessionStorage.getCurrencyUnit(), [Validators.required]),
      language: this.fb.nonNullable.control('', []),
    });

    this.isSubmitDisabled$.next(false);
    this.formConfirmSaveService.setForm(this.form);
  }

  submitForm(): void {
    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    this.isSubmitDisabled$.next(true);
    const { name, currency } = this.form.controls;

    this.updateOrganizationSite({
      id: this.organization.id,
      name: name.value.trim(),
      currency: currency.value as Currency,
      timezone: this.organization.timezone,
    });
  }

  reload(): void {
    this.redirectService.redirectToOrgSettings(this.organization.id);
    this.reloadOrganization.next(this.organization.id);
  }

  getCurrencyList() {
    return this.organizationStorage.getCurrencyList().pipe(map((res) => (res.data as CurrencyEnum).__type.enumValues));
  }

  getOrganizationSitesByAccount(
    accountId: string,
    page: number,
    filter: OrganizationFilterInput,
    sort: SortOrderInput[],
  ): QueryResult<'organizationSitesByAccount'> {
    return this.organizationStorage.getOrganizationSitesByAccount({
      accountId,
      pageRequestInput: { page, size: 20, sort },
      filter,
    });
  }

  updateOrganizationSite(input: UpdateOrganizationSiteInput): void {
    this.organizationStorage.updateOrganizationSite({ input }).subscribe((res) => {
      this.isSubmitDisabled$.next(false);
      if (res.data?.updateOrganizationSite.updatedOrganization) {
        this.accountStorage.setActiveOrganization(res.data?.updateOrganizationSite.updatedOrganization);
      }
      this.reload();
      if (input.currency) {
        this.sessionStorage.setCurrencyUnit(input.currency);
      }
    });
  }

  organizationSite(id: string): Observable<OrganizationSite> {
    return this.organizationStorage.organizationSite({ id }).pipe(map((res) => res.data.organizationSite!));
  }
}
